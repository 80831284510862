import { Currency, ETHER, Token } from '@abstra-dex/sdk'
import { VelasIcon, HelpIcon, Flex } from 'packages/uikit'
import { useMemo } from 'react'
import styled from 'styled-components'
import { WrappedTokenInfo } from 'state/types'
import useHttpLocations from '../../hooks/useHttpLocations'
import getTokenLogoURL from '../../utils/getTokenLogoURL'
import Logo from './Logo'

const StyledLogo = styled(Logo).attrs({ helpColor: 'black' })`
  width: 100%;
`

const BoxIcon = styled(Flex)`
  overflow: hidden;
  border-radius: 50%;

  width: 100%;
  aspect-ratio: 1/1;
  height: auto;

  flex-shrink: 0;
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
  helpColor,
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
  helpColor?: string
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return []

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.address)]
      }
      return [getTokenLogoURL(currency.address)]
    }
    return []
  }, [currency, uriLocations])

  if (currency === ETHER) {
    // return <HelpIcon width={size} style={style} color="black" />
    return <VelasIcon width={size} style={style} />
  }

  return (
    <BoxIcon maxWidth={size} style={style}>
      <StyledLogo helpColor={helpColor} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} />
    </BoxIcon>
  )
}
