import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      id="katman_1"
      xmlns="http://www.w3.org/2000/svg"
      // width="64"
      // height="24"
      viewBox="0 0 24 24"
      version="1.1"
      {...props}
    >
      <path
        d="M1 6.00042V17.9996L11.9999 24L22.9997 17.9996V6.00042L11.9999 0L1 6.00042Z"
        fill="url(#paint0_linear_1045_5858)"
      />
      <path
        d="M17.2801 8.8569L6.65039 14.9384V21.0824L12.0001 23.9999V17.9454L23 11.999V6.00028L17.2801 2.88013V8.8569Z"
        fill="#002D74"
      />
      <path
        d="M6.71986 15.143L17.3478 9.06145V2.91743L11.9999 0V6.05448L1 11.9992V17.9996L6.71986 21.1197V15.143Z"
        fill="#002D74"
      />
      <path d="M17.4338 2.96305L12 0V6.05448L17.4338 9.0378" fill="url(#paint1_linear_1045_5858)" />
      <path d="M11.9995 17.9456L6.56574 14.9961L6.55322 21.0303L11.9995 24.0001" fill="url(#paint2_linear_1045_5858)" />
      <path
        d="M17.4335 14.9959L12.0015 17.9759L6.56592 14.9959V9.03776L12.0015 6.05444L17.4335 9.03776L15.1681 10.2777L12.0015 8.53942L8.82954 10.2777V13.756L12.0015 15.4943L15.1681 13.756L17.4335 14.9959Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25402 17.861L11.9999 23.7229L22.7457 17.861V6.13898L11.9999 0.277131L1.25402 6.13898V17.861ZM11.9999 0L22.9997 6.00042V17.9996L11.9999 24L1 17.9996V6.00042L11.9999 0Z"
        fill="url(#paint3_linear_1045_5858)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1045_5858"
          x1="1"
          y1="11.9992"
          x2="22.9997"
          y2="11.9992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#14DCFF" />
          <stop offset="0.02" stopColor="#11C9F0" />
          <stop offset="0.07" stopColor="#0DA0CF" />
          <stop offset="0.13" stopColor="#097CB3" />
          <stop offset="0.19" stopColor="#055F9C" />
          <stop offset="0.25" stopColor="#03498A" />
          <stop offset="0.32" stopColor="#01397D" />
          <stop offset="0.4" stopColor="#002F76" />
          <stop offset="0.5" stopColor="#002D74" />
          <stop offset="0.56" stopColor="#003479" />
          <stop offset="0.64" stopColor="#034889" />
          <stop offset="0.74" stopColor="#0668A3" />
          <stop offset="0.85" stopColor="#0C96C7" />
          <stop offset="0.97" stopColor="#12D0F5" />
          <stop offset="0.99" stopColor="#14DCFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1045_5858"
          x1="13.7523"
          y1="7.68804"
          x2="16.385"
          y2="-2.00189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E344FF" />
          <stop offset="0.04" stopColor="#CD41F1" />
          <stop offset="0.13" stopColor="#9D3CD4" />
          <stop offset="0.23" stopColor="#7338BA" />
          <stop offset="0.33" stopColor="#4F35A4" />
          <stop offset="0.44" stopColor="#323293" />
          <stop offset="0.55" stopColor="#1C2F85" />
          <stop offset="0.67" stopColor="#0C2E7B" />
          <stop offset="0.81" stopColor="#022D75" />
          <stop offset="1" stopColor="#002D74" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1045_5858"
          x1="7.53305"
          y1="22.1993"
          x2="10.7275"
          y2="16.6708"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#002D74" />
          <stop offset="0.2" stopColor="#002F73" />
          <stop offset="0.34" stopColor="#033973" />
          <stop offset="0.46" stopColor="#084973" />
          <stop offset="0.58" stopColor="#0E5F73" />
          <stop offset="0.69" stopColor="#177C72" />
          <stop offset="0.8" stopColor="#22A071" />
          <stop offset="0.9" stopColor="#2ECA70" />
          <stop offset="0.99" stopColor="#3CFA70" />
          <stop offset="1" stopColor="#3EFF70" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1045_5858"
          x1="11.9988"
          y1="24.2238"
          x2="11.9988"
          y2="-0.221829"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#14DCFF" />
          <stop offset="0.5" stopColor="#3EFF70" />
          <stop offset="1" stopColor="#14DCFF" />
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default Icon
